@font-face {
  font-family: "Lato";
  src: url("./lato.woff2") format("woff2");
}

@font-face {
  font-family: "Monument";
  src: url("./monument.woff2") format("woff2");
}

@font-face {
  font-family: "Telegraf";
  src: url("./telegraf.ttf") format("ttf");
}
@font-face {
  font-family: "TelegrafBold";
  src: url("./telegrafbold.woff2") format("woff2");
}

html,
body {
  margin: 0;
  padding: 0;
  background-position: center center;
  background-size: cover;
  color: white;
  font-family: montserrat, sans-serif;

  font-weight: 300;

  font-style: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  background-color: #0b0b0b;
  background-position: center center;
  background-size: cover;
  color: white;
  font-family: montserrat, sans-serif;

  font-weight: 300;

  font-style: normal;
  font-style: normal;
}

.navbar {
  background-color: #030303;
  padding: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 8px 8px 20px 0px rgb(0 0 0 / 15%);
}

.navbar img {
  width: 180px;
}

.action-button {
  background-color: rgba(255, 255, 255, 0.805);
  border: 0;
  border-radius: 300px;
  box-shadow: 8px 8px 20px 0px rgba(255, 0, 0, 0.05);
  color: #fff;
  top: -60px;
  height: 40px;
  position: relative;
  left: 60%;
  width: 40px;
}

.action-button.btn-left {
  margin-left: 24px;
}

.video-preview-box:first-child .action-button {
  background-color: rgba(255, 255, 255, 0.805);
  border: 0;
  border-radius: 300px;
  box-shadow: 8px 8px 20px 0px rgba(255, 0, 0, 0.05);
  color: #fff;
  top: -60px;
  height: 48px;
  position: relative;
  left: 64%;
  width: 48px;
}

.video-preview-box:first-child .action-button.btn-left {
  margin-left: 24px;
}

/* --e-global-color-primary: #070F7F;
    --e-global-color-secondary: #003366;
    --e-global-color-text: #003366;
    --e-global-color-accent: #8B04F9;
    --e-global-color-43bcab80: #6EC1E4;
    --e-global-color-374225cd: #54595F;
    --e-global-color-752f37c: #7A7A7A;
    --e-global-color-7c5eb59: #61CE70;
    --e-global-color-250b136d: #4054B2;
    --e-global-color-15919e15: #23A455;
    --e-global-color-129e2d69: #000;
    --e-global-color-7ce97e50: #FFF;
    --e-global-color-719db16: #0F0F35;
    --e-global-color-080694e: #6834F7;
    --e-global-color-d360cc1: #67ED94;
    --e-global-color-12969a9: #FFE6F7; */

.action-button:hover {
  background-color: rgb(255, 126, 126);
}

.logos-grid-container {
  display: inline-grid;
  grid-template-columns: 40% 40%;
  grid-column-gap: 50px;
  justify-content: center;
  justify-items: center;
  margin: 50px auto;
  width: 100%;
}

.logos-grid-container img {
  max-width: 300px;
}

.sponsored-by h1 {
  font-size: 1.6rem;
  margin: 25px auto;
  text-align: center;
}

@media screen and (max-width: 1300px) {
  .qrcode {
    position: absolute;
    bottom: 24px;
    padding: 24px;
    background-color: white;
    border-radius: 24px;
    left: 388px;
    opacity: 0.6;
  }
}

@media screen and (min-width: 1300px) {
  .qrcode {
    position: absolute;
    right: 24px;
    padding: 24px;
    background-color: white;
    border-radius: 24px;
    top: 388px;
    opacity: 0.6;
  }
}
.video-preview-container {
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 12px;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.video-preview-box {
  width: 29%;
  position: relative;
  padding: 6px;
}

.video-preview-box:first-child {
  width: 49%;
  position: relative;
  padding: 16px 160px;
}

.video-preview-box img {
  border-radius: 20px;
  width: 100%;
}

.video-preview-qr {
  cursor: move;
}

.video-preview-box .svg-qrcode {
  background-color: #fff;
  border-radius: 24px;
  bottom: 120px;
  height: 120px;
  left: calc(50% - 80px);
  opacity: 0.6;
  padding: 20px;
  position: absolute;
  width: auto;
}

.video-preview-box:first-child .svg-qrcode {
  background-color: #fff;
  border-radius: 24px;
  bottom: 150px;
  height: 220px;
  left: calc(50% - 130px);
  opacity: 0.6;
  padding: 20px;
  position: absolute;
  width: auto;
}

.video-player {
  display: flex;
  justify-content: center;
  margin: 14px auto;
  margin-bottom: 0;
  /* width: calc(100% - 100px); */
}

.video-player video {
  width: fit-content;
  height: fit-content;
  max-width: 80%;
  max-height: 900px;
  border-radius: 6px;
  box-shadow: 8px 8px 20px 0px rgba(0, 0, 0, 0.3);
}

.video-download {
  display: flex;
  justify-content: center;
  width: 82%;
  margin-left: 9%;
  margin-top: 14px;
}

.video-download a {
  background-color: #fff;
  border-radius: 300px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  padding: 16px 48px;
  text-decoration: none;
}

.video-download a:hover {
  background-color: #ededed;
  box-shadow: 8px 8px 20px 0px rgba(255, 0, 0, 0.05);
  font-weight: 700;
  padding: 16px 60px;
  transition: 0.3s ease-in-out;
}

.instructions {
  display: flex;
  justify-content: center;
  margin: 25px auto;
  width: calc(100% - 100px);
}

.instructions a {
  color: rgb(255, 0, 0);
  text-decoration: none;
}

.instructions a:hover {
  font-weight: 500;
}

.instruction-paragraph {
  width: 100%;
  text-align: left;
}

.sponsored-by h5 {
  color: #ffffff;
  font-family: "Monument Extended", Lato, Sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 1px;
  font-size: 24px;
  line-height: 32px;
}

.sponsored-by p {
  color: #ffffff;
  font-family: "Telegraf", Lato, Sans-serif;
  font-size: 24px;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 34px;
}

.sponsored-by {
  margin: 90px;
  background-color: #111;
  color: #0f0f35;
  display: flex;
  flex-direction: column;
  padding: 24px 48px;
  align-items: center;
}

.sponsored-by a {
  font-family: "Telegrafbold", Lato, Sans-serif;
  font-size: 20px;
  font-weight: bold;
  fill: #fff;
  color: #fff;
  background-color: #f00;
  text-transform: none;
  text-decoration: none;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 9px 5px 10px 0px rgb(0 0 0 / 50%);
  padding: 20px 30px 20px 30px;
}

.video-download a {
  font-family: "Telegrafbold", Lato, Sans-serif;
  font-size: 20px;
  font-weight: bold;
  fill: #fff;
  color: #fff;
  background-color: #e70000;
  text-transform: none;
  text-decoration: none;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 9px 5px 10px 0px rgb(0 0 0 / 50%);
  padding: 12px 0px;
  text-align: center;
  display: block;
  width: 79%;
}

.instruction-paragraph {
  color: #ffffff;
  font-family: "Telegraf", Lato, Sans-serif;
  font-size: 24px;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 4px;
  margin-top: 4px;
  text-align: center;
}

.instruction-paragraph a {
  font-family: "Telegrafbold", Lato, Sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: none;
  text-decoration: none;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 9px 5px 10px 0px rgb(0 0 0 / 50%);
}

.registrati-box {
  margin: 90px;
  background-color: #111;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 24px 48px;
  align-items: center;
}
.registrati-title {
  color: #fff;
  font-family: "Telegraf", Lato, Sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
}

.registrati-cta {
  color: #0f0f35;
  font-family: "Telegraf", Lato, Sans-serif;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 8px 14px 8px;
  margin-top: 16px;
  width: 80%;
  background-color: #ffffff;
  border: none !important;
  outline: none !important;
  border-radius: 2px;
}

.checkbox label {
  color: #999;
  font-size: 11px;
}

.checkbox label a {
  color: #ccc;
  font-size: 11px;
}

.registrati-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.registrati-form .checkbox {
  margin-top: 14px;
  width: 90%;
}

.registrati-form .checkbox input {
  width: 16px;
}

.registrati-form button {
  font-family: "Telegrafbold", Lato, Sans-serif;
  font-size: 20px;
  font-weight: bold;
  fill: #fff;
  color: #fff;
  background-color: #e70000;
  text-transform: none;
  text-decoration: none;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 9px 5px 10px 0px rgb(0 0 0 / 50%);
  padding: 18px 40px;
  margin: auto;
  margin-top: 44px;
  border: none;
}

@media screen and (max-width: 600px) {
  .sponsored-by h1 {
    font-size: 1.3rem;
  }
  .logos-grid-container {
    display: grid;
    margin: 25px auto;
    grid-template-columns: 80%;
    grid-row-gap: 25px;
  }
  .video-player {
    /* width: 100%;
    aspect-ratio: 0.9; */
    max-height: 70%;
    max-width: 82%;
  }
  .video-player video {
    /* width: calc(100% - 50px); */
    height: 100%;
    width: 100%;

    /* object-fit: cover; */
  }
  .instructions {
    width: calc(100% - 50px);
  }
  .video-download a {
    box-shadow: 8px 8px 20px 0px rgba(255, 0, 0, 0.05);
  }

  .sponsored-by {
    margin: 20px;
    display: flex;
    flex-direction: column;
    padding: 24px 28px;
    align-items: center;
  }

  .registrati-box {
    margin: 20px;
    display: flex;
    flex-direction: column;
    padding: 24px 28px;
    align-items: center;
  }

  h5 {
    font-size: 20px;
    line-height: 28px;
  }

  p {
    font-size: 15px;
    margin-bottom: 34px;
  }

  a {
    font-size: 20px;
  }
}

#root:has(> .ov-hidden) {
  overflow: hidden;
}

body:has(> div .ov-hidden) {
  overflow: hidden;
}

.bg-transparent {
  background-color: transparent !important;
}

.login-box {
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  row-gap: 8px;
  height: fit-content;
  padding: 28px;
  border-radius: 12px;
  background-color: #171717;
}

.login-box input {
  padding: 4px 8px;
  min-width: 220px;
}

.login-box .login-btn {
  padding: 4px 8px;
  min-width: 220px;
  background-color: #e70000;
  color: #fff;
  text-align: center;
}

.video-share {
  font-family: "Telegrafbold", Lato, Sans-serif;
  font-size: 20px;
  font-weight: bold;
  fill: #fff;
  color: #fff;
  background-color: #020202;
  text-transform: none;
  text-decoration: none;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 9px 5px 10px 0px rgb(0 0 0 / 50%);
  padding: 14px 0px;
  text-align: center;
  width: 79%;
  display: block;
}

.video-preview-box.active {
  background-color: green;
  border-radius: 20px;
}
.video-preview-box.active.ob {
  background-color: red;
  border-radius: 20px;
}

.video-preview-box.active:first-child {
  background-color: transparent;
  border-radius: 20px;
}
.video-preview-box.active.ob:first-child {
  background-color: red;
  border-radius: 20px;
}

@media screen and (min-width: 1530px) {
  .video-preview-box.active:first-child {
    background-color: green;
    border-radius: 20px;
  }
  .video-preview-box.active.ob:first-child {
    background-color: red;
    border-radius: 20px;
  }
  .qrcode {
    position: absolute;
    right: 24px;
    padding: 24px;
    background-color: white;
    border-radius: 24px;
    top: 388px;
    opacity: 0.6;
  }

  .video-preview-box:first-child .action-button {
    background-color: rgba(255, 255, 255, 0.805);
    border: 0;
    border-radius: 300px;
    box-shadow: 8px 8px 20px 0px rgba(255, 0, 0, 0.05);
    color: #fff;
    top: -60px;
    height: 40px;
    position: relative;
    left: 64%;
    width: 40px;
  }

  .video-preview-box {
    width: 400px;
    position: relative;
    padding: 6px;
  }

  .video-preview-box:first-child {
    width: 400px;
    position: relative;
    padding: 6px;
  }

  .video-preview-box img {
    border-radius: 20px;
    width: 100%;
  }

  .video-preview-qr {
    cursor: move;
  }

  .video-preview-box .svg-qrcode {
    background-color: #fff;
    border-radius: 24px;
    bottom: 120px;
    height: 120px;
    left: calc(50% - 80px);
    opacity: 0.6;
    padding: 20px;
    position: absolute;
    width: auto;
  }

  .video-preview-box:first-child .svg-qrcode {
    background-color: #fff;
    border-radius: 24px;
    bottom: 120px;
    height: 120px;
    left: calc(50% - 80px);
    opacity: 0.6;
    padding: 20px;
    position: absolute;
    width: auto;
  }
}

.clip-number {
  position: absolute;
  bottom: 60px;
  left: 20px;
}
